import { MarketCountryCode } from '@backmarket/http-api'

export const MODAL_NAMES = {
  ADD_TO_CART: 'ADD_TO_CART',
  BUNDLE: 'BUNDLE',
  CARRIERS_COMPATIBILITY: 'CARRIERS_COMPATIBILITY',
  COPY_LEVY: 'COPY_LEVY',
  RENEWED_IMPACT: 'RENEWED_IMPACT',
  EXCLUDED_ACCESSORIES: 'EXCLUDED_ACCESSORIES',
  FAQ: 'FAQ',
  GRADES: 'GRADES_DETAILS',
  MEDIA_VIEWER: 'MEDIA_VIEWER',
  NEW_BATTERY: 'NEW_BATTERY',
  PREMIUM_BATTERY: 'PREMIUM_BATTERY',
  PERKS: 'PERKS',
  PREPAID_PLAN: 'PREPAID_PLAN',
  SERVICE_FEE: 'SERVICE_FEE',
  SIM: 'SIM',
  TECH_SPECS: 'TECH_SPECS',
  REASSURANCE: 'REASSURANCE',
  TRADE_IN: 'TRADE_IN',
  VERIFIED_REFURBISHED: 'VERIFIED_REFURBISHED',
  BOUYGUES: 'BOUYGUES_OFFERS',
  VISIBLE_BY_VERIZON: 'VISIBLE_BY_VERIZON',
  BATTERY_REPLACEMENT: 'BATTERY_REPLACEMENT',
  BATTERY_REPLACEMENT_BENEFITS: 'BATTERY_REPLACEMENT_BENEFITS',
}

export const PREMIUM_DISABLED_COUNTRIES = [
  MarketCountryCode.AU,
  MarketCountryCode.JP,
]

export const VALID_GRADES = [9, 10, 11, 12]
